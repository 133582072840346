import * as React from 'react'
import { useEffect } from 'react'

import Link from 'next/link'

interface ISmoothScrollProps {
  name: React.ReactNode;
  href: string;
  selected: boolean;
  onClick: () => void;
}

const SmoothScroll: React.FC<ISmoothScrollProps> = (
  props: ISmoothScrollProps,
) => {
  const { name, href, selected, onClick } = props
  const linkRef = React.useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    const linkElement = linkRef.current
    if (linkElement) {
      linkElement.addEventListener('click', (e) => {
        if (href.startsWith('#') && !href.startsWith('#http')) {
          e.preventDefault()
          const target = document.getElementById(href.substring(1))
          if (target) {
            window.scrollTo({
              top: target.offsetTop,
              behavior: 'smooth',
            })
          }
        }
      })
    }
  }, [href])

  //   const active = selected ? 'block' : 'hidden'
  const styled = selected
    ? 'py-1 px-2 border-b-4 font-semibold hover:text-green-500 transition duration-300 hover:bg-jacarta-600 rounded'
    : 'py-1 px-2 hover:text-green-500 transition duration-300 hover:bg-jacarta-600 rounded-lg'

  return (
    <>
      <Link className={styled} ref={linkRef} href={href} onClick={onClick}>
        {name}
      </Link>
    </>
  )
}

export default SmoothScroll
