import * as React from 'react'

import SmoothScroll from './SmoothScroll'

interface IProps {
  url: string
  text: string
  selected: boolean
  onClick?: (url: string) => void;
}

export const ItemMenu = (props: IProps) => {
  const { url, text, selected, onClick } = props

  const handleClick = () => {
    onClick?.(url)
  }

  return (
    <SmoothScroll
      href={url}
      name={text}
      selected={selected}
      onClick={handleClick}
    />
  )
}
