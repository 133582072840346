'use client'
import React, { useState, useEffect } from 'react'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { addMobileMenuToggle, removeMenuActive } from 'app/lib'
import { usePathname } from 'next/navigation'

import { IMenu } from '@/components/menu'
import { ItemMenu } from './ItemMenu'

interface IProps {
  children?: React.ReactNode;
  menuItems: IMenu[];
}

const Menu = (props: IProps) => {
  const { children, menuItems } = props
  const pathname = usePathname()
  const [open, setOpen] = useState<boolean>(false)

  const handleSelectItem = () => {
    setOpen(false)
  }

  useEffect(() => {
    addMobileMenuToggle()
    return () => {
      removeMenuActive()
    }
  }, [])

  const items = menuItems.map((element) => {
    return(
      <ItemMenu
        key={element.id}
        url={element.link}
        text={element.name}
        selected={pathname === element.link}
        onClick={handleSelectItem}
      />
    )})

  const active = open ? 'block' : 'hidden'

  return (
    <header>
      <nav className="fixed top-0 z-20 w-full backdrop-blur transition-colors">
        {children}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-2 space-x-1 md:hidden">
          <Bars3Icon
            onClick={() => {
              setOpen(!open)
            }}
            className="text-gray-400 mx-3 my-3 h-10 w-10 cursor-pointer rounded border-[1px] border-solid p-2 text-sm "
          />
        </div>
        <div
          className={`md:flex md:flex-grow md:flex-row md:justify-end md:space-x-1 ${active} bg-gray-900 border-gray-700 mt-4 flex flex-col p-4 font-medium md:mt-0 md:flex-row`}
        >
          {items}
          {/* <DarkMode /> */}
        </div>
      </nav>
    </header>
  )
}

export default Menu
